import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Logo from '../logo/logo';
const Header = ({login}) => {
    const [isScrolled, setIsScrolled] = useState(false);
      // Detect scroll position
  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    setIsScrolled(scrollTop > 50); // Show header when scroll is beyond 50px
  };

  // Set up scroll listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
    return (
        <motion.div
        className="sticky top-0 w-full bg-white text-gray-500 z-50 py-2.5 px-5 shadow-md font-special-elite"
        initial={{ y: '-100%' }}
        animate={{ y: isScrolled ? '0%' : '-100%' }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      >
        <div className='header-content'>
          <Link className='logo' to="/">
           <Logo size={'small'} />
          </Link>
          <nav className='nav-content'>
            <ul className='nav-links'>
              <li><a href="blog">Blog</a></li>
              <li><a href="https://github.com/univault-org/srpt-protocol" target="__blank">GitHub</a></li>
             { !login && <li><a href="/login">Login</a></li> }
             {/*  <li><a href="#section3">Section 3</a></li> */}
            </ul>
          </nav>
        </div>
      </motion.div>
    )
}

export default Header;