import React from 'react'
import './logo.css'

function Logo({size}) {
    return (

         <div className="angelo-logo-container">
            <div className="angelo-logo-left-text font-bold">
              <h1 style={{fontSize: size ==='large' ? "3.5rem" : "1rem"}}>UNIVAULT</h1>
            </div>       
            <div className="angelo-logo-image">
              <div id="earth" style={{width: size ==='large' ? "1.5rem" : "0.8rem", height: size ==='large' ? "1.5rem" : "0.8rem"}}></div>
            </div>
            <div className="angelo-logo-right-text font-bold">
              <h1 style={{fontSize: size ==='large' ? "3.5rem" : "1rem", marginLeft: '2px'}}>RG</h1>
            </div>
        </div>
    )
}

export default Logo;