// import {useState, useEffect} from 'react'
import '../App.css';
import Box from '../Box';
import Logo from '../components/logo/logo';

function Home() {
//   const [isScrolled, setIsScrolled] = useState(false);

//   // Detect scroll position
//   const handleScroll = () => {
//     const scrollTop = window.pageYOffset;
//     setIsScrolled(scrollTop > 50); // Show header when scroll is beyond 50px
//   };

//   // Set up scroll listener
//   useEffect(() => {
//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

   // Scroll to top function
   const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling animation
    });
  };

  const ownershipText = "Owning personal data means having the right to fully control it, much like control over one’s own body. There is the right to retain it, use it as desired, share it, or even delete it if so chosen. Personal data can be passed on to others and its value can be utilized. In essence, this data belongs to the individual to manage, protect, and decide how it is used."
  const privacyText = "Personal data are any data that could reasonably be linked to a specific, living or deceased individual. Like our DNA, which we pass down through generations, personal data carries unique information that defines us and connects us to others. In nature, each species has its own distinct way of expressing affection, understood only within its own circle and impossible for others to decode. Similarly, our personal data must be safeguarded, ensuring it is shared only with those who have the right to access it."
  const accessRight = "Access Rights ensure that only authorized individuals or entities can view, use, or process personal data. Access is governed by principles of necessity and consent, protecting the dignity and autonomy of each individual. Through collective oversight and transparent procedures, it is resolved that these rights are upheld, ensuring personal data remains secure and access is judiciously managed according to the highest ethical standards."
  const securityText = "Strong security measures are essential for protecting personal data. This includes implementing reliable encryption, conducting regular security audits, and enforcing strict access controls to prevent unauthorized access and breaches. Just as a vault safeguards valuable possessions, robust security practices ensure personal information remains safe and intact. Advanced technologies and protocols are employed to defend against potential threats, ensuring personal data is protected at every stage—from generation and processing to transmission and storage."
  // const wellBeing = "Digital technologies expand the boundaries of information available to people and enhance human productivity, but can also imply risks for people's well-being, especially mental health. One of the core missions of Angelo Network is to empower health systems to create holistic views of patients, personalize treatments, advance treatment methods, and to improve communication between data owners and data service providers for the purpose of enhancing Well-Being as well as to reduce the effect of the most unfortunate event when free-will beings take their own lives."
  // const truth = "Information and data is the source of Truth to reveal the hidden unknown Universe. Personal and identity data as literally constituting the Person. Personal identity and personal data refer back to one and the same thing."
  
 
  return (
    <div>
       <div
        className="sticky top-0 w-full bg-white text-gray-500 z-50 py-2.5 px-5 shadow-md font-special-elite"
      >
        <div className='header-content'>
          <div className='logo'>
           <Logo size={'small'} />
          </div>
          <nav className='nav-content'>
            <ul className='nav-links'>
              <li><a href="blog">Blog</a></li>
              <li><a href="https://github.com/univault-org/srpt-protocol" target="__blank">GitHub</a></li>
             {/*  <li><a href="#section3">Section 3</a></li> */}
            </ul>
          </nav>
        </div>
      </div>
     <div className='page-container'>
       {/* Sticky Header */}


      <div className='dark-bg'>
        <h1 className='font-people'>We The Universal Citizens</h1>
        <div className='main-title'>
          <div className='title-one'>
           <p>THE</p>
          </div>
         <div className='title-two'>
          <p>UNIVERSAL</p>
         </div>
         <div className='title-three'>
           <p>& PERSONAL DATA VAULT</p>
          </div>
         {/* <div className='logo-container' style={{marginBottom: '40px', margintop: '20px'}}>
            <Logo size={'large'}/>
         </div> */}
        </div>
      </div>
      <div className="slide-divider"></div>
      <Box title={'Preamble'} articleNumber={"1"}   scrollToTop={scrollToTop} />
      <div className="slide-divider"></div>
      <Box title={'Ownership'} text={ownershipText} articleNumber={"2"}   scrollToTop={scrollToTop} />
      <div className="slide-divider"></div>
      <Box title={'Privacy'} text={privacyText} articleNumber={"3"}   scrollToTop={scrollToTop} />
      <div className="slide-divider"></div>
      <Box title={'Security'} text={securityText} articleNumber={"4"}   scrollToTop={scrollToTop} />
      <div className="slide-divider"></div>
      <Box title={'Access Right'} text={accessRight} articleNumber={"5"}  scrollToTop={scrollToTop} />
      {/* <Box title={'Well-Being'} text={wellBeing}/>
      <Box title={'Truth'} text={truth}/> */}


      <div className='dark-bg-content-page' style={{maxHeight: '10px'}}>
      </div>
      
     </div>
     </div>
  );
}

export default Home;
