/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Routes, Route
} from "react-router-dom";
import Home from "./pages/home";
import BlogList from "./pages/blogList";
import BlogPost from "./pages/blogPost";
import Login from "./pages/login";
import useRefreshToken from "./hooks/useRefreshToken";
export default function App() {
  const refresh = useRefreshToken();

  useEffect(() => {
    // Define an async function inside useEffect to handle the async refresh call
    const handleRefresh = async () => {
      try {
          // eslint-disable-next-line react-hooks/exhaustive-deps
       await refresh(); // Assuming refresh() is an async function
      } catch (error) {
        console.error("Failed to refresh authentication:", error);
      }
    };

    handleRefresh();

  }, []); // Ensure refresh and setAuth are stable and do not cause rerenders

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/blog" element={<BlogList />} />
      <Route path="/blog/:title" element={<BlogPost />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}