import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';
import OtpInput from 'react-otp-input';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Header from '../components/ui/header';

export default function Login() {
    const { auth, setAuth } = useAuth();
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [isSentOTP, setIsSentOTP] = useState(false);
    const [errorTextMessage, setErrorTextMessage] = useState("");
    const axiosPrivate = useAxiosPrivate();
    const navigateTo = useNavigate();

    const handleEmailSubmit = () => {
        const reqObj = {
            communities: JSON.stringify([window.location.hostname]),
            email: email
        };
        axiosPrivate.post("/api/member", reqObj).then(result => {
            if (result?.status === 200 && result?.data.statusString.includes("sent OTP ok")) {
                setShowOtpInput(true);
                setIsSentOTP(true);
            }
        });
    };
    useEffect(() => {
     if(auth?.userName) {
        const lastPage = sessionStorage.getItem('lastPage');
        navigateTo(lastPage || '/blog');
     }
    }, [auth, navigateTo])

    useEffect(() => {
        const handleOtpLogin = () => {
            const reqObj = {
                UserId: 1,
                email: email,
                hostname: window.location.hostname,
                otp
            };
            axiosPrivate.post("/api/check-otp/web-member?appName=univault", reqObj).then(result => {
                const { data } = result;
                if (data?.code === 200) {
                    setAuth(data.data);
                } else {
                    setOtp("");
                    if (result.data?.message === 'the password has expired') {
                        setErrorTextMessage("The One-Time-Password has expired. Please login again.");
                        setTimeout(() => window.location.reload(), 5000);
                    } else {
                        setErrorTextMessage("Password invalid! Please check the OTP sent to your email and try again.");
                    }
                }
            });
        };
    
        if (otp.length === 6) {
            if (!isNaN(parseInt(otp))) {
                handleOtpLogin();
            } else {
                setErrorTextMessage("Passcode is not valid!");
            }
        }
    }, [axiosPrivate, email, otp, setAuth]); // No need for additional dependencies
    

    return (
        <div className='mt-[55px]'>
         <Header login={true} />
        <div className='flex justify-center items-center'>
            <div className="p-4 max-w-3xl bg-gray-400">
                <div className='text-center text-rose-500 pr-2'>{errorTextMessage}</div>
                <div className="font-medium text-gray-800 dark:text-gray-100 mb-2">
                    {isSentOTP ? "Enter One-Time Passcode" : "Sign Up/Sign In"}
                </div>
                {!isSentOTP && (
                    <input
                        type="email"
                        placeholder="Enter your email"
                        className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:border-lime-500"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                )}
                {!showOtpInput ? (
                    <button
                        onClick={handleEmailSubmit}
                        className="w-full text-black p-2 rounded-md bg-gray-200"
                    >
                        Get One-Time Passcode
                    </button>
                ) : (
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={{
                            width: "2.5rem",
                            fontWeight: 800
                        }}
                        containerStyle="flex justify-center space-x-2"
                    />
                )}
            </div>
        </div>
        </div>
    );
}
