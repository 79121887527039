import React from 'react';
import Header from '../components/ui/header';

const Posts = [
    {
        id: 1,
        title: "Univault's Research on Distributed Computing for Universal Basic Income - UBI Initiative",
        date: 'September 15, 2024',
        author: 'Univault Team',
        abstract: `
      At Univault, we believe technology plays a crucial role in reducing suffering, particularly in its extreme forms. 
      This article explores how technological advancements can help mitigate unhappiness and promote collective well-being.
    `,
        contentUrl: '/posts/UBI.md',
        link: '/blog/univault-active-research-areas',
    },
    {
        id: 2,
        title: "The North Star: Guiding Our Journey to Oneness",
        date: 'September 24, 2024',
        author: 'Univault Team',
        abstract: `
      For any journey, establishing a North Star is crucial. This article discusses the importance of defining our ultimate destination
      and how it relates to our creative essence and oneness with the Creator. We explore the practical implications of this perspective
      in guiding our innovations and technological advancements.
    `,
        contentUrl: '/posts/NorthStar.md',
        link: '/blog/the-north-star',
    },
    {
        id: 3,
        title: "The Evolution of Communication Protocols: Introducing SRPT",
        date: 'September 27, 2024',
        author: 'Univault Team',
        abstract: `
      From rotary phones to the internet, communication protocols have shaped our world. This article explores the limitations of current internet infrastructure and introduces the Satellite Relay Package Transfer Protocol (SRPT). Learn how SRPT aims to revolutionize data transfer, enhance security, and pave the way for the next generation of AI and global connectivity.
    `,
        contentUrl: '/posts/SRPT.md',
        link: '/blog/the-evolution-of-communication-protocols',
    },
    {
        id: 4,
        title: "Creating The Physical Form of Our Personal Assistants",
        date: 'October 1, 2024',
        author: 'Univault Team',
        abstract: `
      As we stand on the brink of a technological revolution, this article explores the vision of creating physical forms for personal assistants. By embedding AI in secure, air-gapped devices, we can enhance security, privacy, and personalization. Discover how these advancements could transform our daily lives, making personal assistants not just digital entities but trusted companions that empower us in our journey toward greater creativity and fulfillment.
    `,
        contentUrl: '/posts/PA.md',
        link: '/blog/creating-the-physical-form-of-our-personal-assistants',
    },
    {
        id: 5,
        title: "Harmonics and Overtones in Ultrasonic Data Modulation and Transfer",
        date: 'October 10, 2024',
        author: 'Univault Team',
        abstract: `
      Explore a new area of research in ultrasonic data modulation and its potential to improve secure communications. This article delves into the mathematical foundations of harmonics and overtones in ultrasonic waves, and how they can be leveraged to enhance data transfer efficiency and security. We present a use case for Point of Sale (POS) systems, demonstrating how ultrasonic communication combined with satellite technology can overcome the limitations of current NFC solutions. Discover how this innovative approach could reshape the landscape of short-range, high-bandwidth data transfer across various industries, from finance to healthcare and beyond.
    `,
        contentUrl: '/posts/HARMONIC.md',
        link: '/blog/harmonic-and-overtone-in-ultrasonic-data-modulation-and-transfer',
    },
];

const BlogList = () => {
    return (
        <div className='bg-white min-h-screen py-12'>
            <div className=''>
                <Header />
            </div>
            <div className="bg-white min-h-screen py-12 px-6 lg:px-24 ">

                <div className='flex justify-center w-[100vw] -mt-6'>
                    <div className="space-y-10 max-w-4xl ">
                        {Posts.map((post) => (
                            <div
                                key={post.id}
                                className="border-b border-gray-200 pb-10 hover:shadow-lg transition-shadow duration-200 ease-in-out px-[40px] pt-10"
                            >
                                <a href={post.link} className="block">
                                    <h2 className="text-2xl font-semibold text-gray-800 mb-4 hover:text-indigo-600 transition-colors duration-150">
                                        {post.title}
                                    </h2>
                                </a>
                                <div className="text-gray-500 text-sm mb-4">
                                    {post.date}
                                </div>
                                <a href={post.link} className="block">
                                    <p className="text-gray-700 leading-relaxed mb-6">{post.abstract}</p>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogList;
