import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import MDEditor from '@uiw/react-md-editor';
import '@uiw/react-md-editor/markdown-editor.css';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import { InlineMath, BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import Header from '../components/ui/header';
import useAuth from '../hooks/useAuth';

// This should be in a separate file and imported here
const Posts = [
        {
            id: 'univault-active-research-areas',
            title: "Univault's Research on Distributed Computing for Universal Basic Income - UBI Initiative",
            contentUrl: '/posts/UBI.md',
        },
        {
            id: 'the-north-star',
            title: "The Intersection of AI Ethics and Societal Progress",
            contentUrl: '/posts/NorthStar.md',
        },
        {
            id: 'the-evolution-of-communication-protocols',
            title: "The Evolution of Communication Protocols: Introducing SRPT",
            contentUrl: '/posts/SRPT.md',
        },
        {
            id: 'creating-the-physical-form-of-our-personal-assistants',
            title: "Creating The Physical Form of Our Personal Assistants",
            contentUrl: '/posts/PA.md',
        },
        {
            id: 'harmonic-and-overtone-in-ultrasonic-data-modulation-and-transfer',
            title: "Harmonics and Overtones in Ultrasonic Data Modulation and Transfer",
            contentUrl: '/posts/HARMONIC.md',
        },
];

// function containsLatex(content) {
//   // Check for inline math: $...$
//   const inlineMathRegex = /\$(?!\s)(.+?)\$/g;
//   // Check for block math: $$...$$
//   const blockMathRegex = /\$\$([\s\S]+?)\$\$/g;
  
//   return inlineMathRegex.test(content) || blockMathRegex.test(content);
// }

export default function BlogPost() {
    const [post, setPost] = useState(null);
    const [content, setContent] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const { auth } = useAuth();
    const location = useLocation();
    const postId = location.pathname.split('/').pop();

    useEffect(() => {
        const fetchPost = async () => {
            const foundPost = Posts.find(p => p.id === postId);
            if (foundPost) {
                setPost(foundPost);
                try {
                    const response = await fetch(foundPost.contentUrl);
                    const text = await response.text();
                    setContent(text);
                } catch (error) {
                    console.error('Error fetching post content:', error);
                    setContent('Error loading post content.');
                }
            } else {
                setContent('Post not found.');
            }
        };

        fetchPost();
    }, [postId]);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        setIsEditing(false);
    };

    const renderMarkdown = (content) => {
        return (
            <ReactMarkdown
                children={content}
                remarkPlugins={[remarkMath]}
                rehypePlugins={[rehypeKatex]}
                components={{
                    h1: ({node, ...props}) => <h1 className="text-4xl font-bold mb-4" {...props}>{props.children}</h1>,
                    h2: ({node, ...props}) => <h2 className="text-3xl font-semibold mt-8 mb-4" {...props}>{props.children}</h2>,
                    h3: ({node, ...props}) => <h3 className="text-2xl font-semibold mt-6 mb-3" {...props}>{props.children}</h3>,
                    p: ({node, ...props}) => <p className="mb-4" {...props} />,
                    ul: ({node, ...props}) => <ul className="list-disc pl-5 mb-4" {...props} />,
                    ol: ({node, ...props}) => <ol className="list-decimal pl-5 mb-4" {...props} />,
                    li: ({node, ...props}) => <li className="mb-2" {...props} />,
                    a: ({node, children, ...props}) => <a className="text-blue-600 hover:underline" {...props}>{children}</a>,
                    blockquote: ({node, ...props}) => <blockquote className="border-l-4 border-gray-300 pl-4 italic my-4" {...props} />,
                    code: ({node, inline, ...props}) => 
                        inline 
                            ? <code className="bg-gray-100 rounded px-1" {...props} />
                            : <pre className="bg-gray-100 rounded p-4 overflow-x-auto"><code {...props} /></pre>,
                    math: ({ value }) => <BlockMath math={value} />,
                    inlineMath: ({ value }) => <InlineMath math={value} />
                }}
            />
        );
    };

    if (!post) {
        return <div>Loading...</div>;
    }

    return (
        <div className='bg-white min-h-screen py-12'>
            <div className=''>
                <Header />
            </div>
            <div className="flex w-[100vw] justify-center container mx-auto px-4 py-6 -mt-10">
                <div className='max-w-3xl w-full'>
                    <Link to="/blog" className="inline-block mb-6 px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition-colors">
                        ← Back to Blog List
                    </Link>
                    {isEditing ? (
                        <div className="mb-6 flex-1 overflow-auto">
                            <MDEditor 
                                value={content} 
                                onChange={setContent} 
                                className="w-full" 
                                height="100%" 
                            />
                            <button
                                onClick={handleSaveClick}
                                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                            >
                                Save
                            </button>
                        </div>
                    ) : (
                        <div>
                            {renderMarkdown(content)}
                            {auth?.userName === "philiptranp"
                                ? <button
                                    onClick={handleEditClick}
                                    className="mt-4 px-4 py-2 bg-green-500 text-white rounded"
                                >
                                    Edit
                                </button>
                                : null}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
