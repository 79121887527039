import axios from 'axios';
import useAuth from './useAuth';
// const BASE_URL = import.meta.env.VITE_APP_SERVER_URL;
const BASE_URL="http://localhost:8081"
axios.defaults.withCredentials = true;
const api = axios.create({
    baseURL: BASE_URL,
    withCredentials: true
    });

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const refresh = async () => {

            const response = await api.get(`${BASE_URL}/api/auth/refresh-token?appName=univault`, {
                withCredentials: true
            });
            setAuth(response.data);
            return response.data.accessToken;
  
    }
    return refresh;
};

export default useRefreshToken;